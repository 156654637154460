

































import Vue from 'vue';
import axios from 'axios';
import { SmartTable, Config, Column, Sorting, PagingOptions } from 'rey-vue-smarttable';
import MessageType from '@/models/MessageType';

export default Vue.extend({
  name: 'messageTypes',
  components: { SmartTable },
  data() {
    return {
      smartTableConfig: new Config(
        (row) => row.messageTypeId,
        [
          new Column({
            title: this.$t('views.systemadmin.messageTypes.tableHeaders.messageTypeId').toString(),
            fieldName: 'messageTypeId',
            fieldType: 'numeric',
            editable: () => false,
            hidden: true,
            hiddenInAddDialog: true
          }),
          new Column({
            title: this.$t('views.systemadmin.messageTypes.tableHeaders.statusNumber').toString(),
            fieldName: 'statusNumber',
            fieldType: 'numeric',
            editable: () => false
          }),
          new Column({
            title: this.$t('views.systemadmin.messageTypes.tableHeaders.displayName').toString(),
            fieldName: 'displayName',
            fieldType: 'string',
            editable: () => false
          }),
          new Column({
            title: this.$t('views.systemadmin.messageTypes.tableHeaders.description').toString(),
            fieldName: 'description',
            fieldType: 'string',
            editable: () => false
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.messageTypes.tableHeaders.isSendingToErpInterfaceActive'
            ).toString(),
            fieldName: 'isSendingToErpInterfaceActive',
            fieldType: 'boolean',
            editable: () => true
          })
        ],
        new Sorting('statusNumber', 'ascending'),
        new PagingOptions(100)
      ).withEfApiActions(axios, 'MessageTypes'),
      selected: undefined as MessageType | undefined,
      validationInProgress: false as boolean
    };
  }
});
